import { MsalProvider, AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { PageLayout } from './components/PageLayout';
import { Route, Routes } from 'react-router-dom';
import AdGuardRules from './pages/AdGuardRules';
import LoggedInUser from './pages/LoggedInUser';
import Herald from './pages/Herald';
import Home from './pages/Home';
import './styles/App.css';

/**
 * Most applications will need to conditionally render certain components based on whether a user is signed in or not. 
 * msal-react provides 2 easy ways to do this. AuthenticatedTemplate and UnauthenticatedTemplate components will 
 * only render their children if a user is authenticated or unauthenticated, respectively. For more, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
const MainContent = () => {
    return (
        <div className="App">
            <AuthenticatedTemplate>
                <Routes>
                    <Route path="/adguardrules" element={<AdGuardRules />} />
                    <Route path="/herald" element={<Herald />} />
                    <Route path="/whoami" element={<LoggedInUser />} />
                    <Route path="/" element={<Home />} />
                </Routes>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <div>
                    <h1>Please login</h1>
                    <p>You are not logged in.</p>
                </div>
            </UnauthenticatedTemplate>
        </div>
    );
};


/**
 * msal-react is built on the React context API and all parts of your app that require authentication must be 
 * wrapped in the MsalProvider component. You will first need to initialize an instance of PublicClientApplication 
 * then pass this to MsalProvider as a prop. All components underneath MsalProvider will have access to the 
 * PublicClientApplication instance via context as well as all hooks and components provided by msal-react. For more, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
const App = ({ instance }) => {
    return (
        <MsalProvider instance={instance}>
            <PageLayout>


                <MainContent />
            </PageLayout>
        </MsalProvider>
    );
};

export default App;