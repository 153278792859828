import React, { useEffect, useState } from 'react';

const Herald = () => {
    const [url, setUrl] = useState("");
    const [pageContent, setPageContent] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const onSubmit = () => {
        var tempUrl = document.getElementById('urlInput').value;
        if (tempUrl === "") {
            return;
        }

        setUrl(tempUrl)
    }

    useEffect(() => {

        const fetchData = async () => {
            if (url === "") {
                return;
            }

            setIsLoading(true);
            fetch('/api/GetHeraldContent?url=' + url)
                .then(response => response.text()) 
                .then(data => {
                    setPageContent(data);
                    setIsLoading(false);
                })
                .catch(error => {
                    setIsLoading(false);
                    console.error(error);
                });
        };

        fetchData();

    }, [url]);



    return (
        <>
            <div className="container mt-3">
                <div className="row">
                    <div className="col-12">
                        <form onSubmit={(e) => e.preventDefault()}>
                            <div class="search">
                                <i class="fa fa-search"></i>
                                <input type="text" id="urlInput" class="form-control" placeholder="Herald's page link." />
                                <button class="btn btn-primary" onClick={onSubmit}>Search</button>
                            </div>

                            {/* <div className="mb-3">
                                <label for="urlInput" className="form-label">Url:</label>
                                <input className="form-control" id="urlInput" />
                            </div>
                            <button type="submit" className="btn btn-primary" onClick={onSubmit}>Submit</button> */}
                        </form>
                    </div>
                </div>

                {
                    isLoading && <div className="row">
                        <div className="col-12">
                            <h3>Loading...</h3>
                        </div>
                    </div>
                }

                {
                    !isLoading &&
                    <>
                        <div style={{ textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: pageContent }} />
                    </>
                }
            </div>
        </>
    );
};

export default Herald;