import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { Button } from 'react-bootstrap';
import { loginRequest } from '../authConfig';
import { Link } from 'react-router-dom';

export const NavigationBar = () => {
    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();
    var tokens = activeAccount?.idTokenClaims;
    var name = tokens?.name ? tokens.name + "'s" : 'My';

    const handleLoginRedirect = () => {
        instance.loginRedirect(loginRequest).catch((error) => console.log(error));
    };

    const handleLogoutRedirect = () => {
        instance.logoutRedirect().catch((error) => console.log(error));
    };

    /**
     * Most applications will need to conditionally render certain components based on whether a user is signed in or not.
     * msal-react provides 2 easy ways to do this. AuthenticatedTemplate and UnauthenticatedTemplate components will
     * only render their children if a user is authenticated or unauthenticated, respectively.
     */
    return (
        <>
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <div className="container-fluid">
                    <a className="navbar-brand" href="/">{name} Home</a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <AuthenticatedTemplate>
                                <li className="nav-item">
                                    <Link className="nav-link" to="adguardrules">Dns Rules</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="herald">Herald</Link>
                                </li>                                
                                <li className="nav-item">
                                <Link className="nav-link" to="whoami">User</Link>
                                </li>                                
                            </AuthenticatedTemplate>
                        </ul>
                    </div>
                    <AuthenticatedTemplate>
                        <div className="d-flex">
                            <Button variant="warning" onClick={handleLogoutRedirect}>
                                Sign out
                            </Button>
                        </div>
                    </AuthenticatedTemplate>
                    <UnauthenticatedTemplate>
                        <div className="d-flex">
                            <Button onClick={handleLoginRedirect}>Sign in</Button>
                        </div>
                    </UnauthenticatedTemplate>
                </div>
            </nav>
        </>
    );
};
