import React from 'react';

function Home() {
    return (
        <div>
            <h1>Welcome to the Home Page</h1>
            <p>This is the content of the home page.</p>
        </div>
    );
}

export default Home;