import { useMsal } from '@azure/msal-react';
import { Container } from 'react-bootstrap';
import { IdTokenData } from '../components/DataDisplay';
import React from 'react';

/**
 * useMsal is hook that returns the PublicClientApplication instance,
 * that tells you what msal is currently doing. For more, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/hooks.md
 */
const LoggedInUser = () => {
    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();

    return (
        <div className="App">

            {activeAccount ? (
                <Container>
                    <IdTokenData idTokenClaims={activeAccount.idTokenClaims} />
                </Container>
            ) : null}
        </div>
    );
};

export default LoggedInUser;
