import React, { useEffect, useState } from 'react';
import useFetchWithMsal from '../hooks/useFetchWithMsal';
import { protectedResources } from "../authConfig";
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';

const ITEMS_PER_PAGE = 10;

const AdGuardRules = () => {
    const [rules, setRules] = useState([]);
    const [updatedRules, setUpdatedRules] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const [submitMessage, setSubmitMessage] = useState(null);
    const [isSubmitSuccessful, setIsSubmitSuccessful] = useState(false);
    const [hasSubmit, sethasSubmit] = useState(false);

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const { execute } = useFetchWithMsal();

    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    const pageItems = 
        rules && rules.length > 0 ? rules.slice(startIndex, endIndex) : [];

    useEffect(() => {

        if (!rules || rules.length === 0) {
            execute('GET', protectedResources.getCustomRulesApi.endpoint)
                .then((response) => {
                    setRules(response);
                    setUpdatedRules(response);
                    setIsLoading(false);
                })
                .catch(error => {
                    setIsLoading(false);
                    console.error(error);
                });
        }



        // const fetchData = async () => {
        //     setIsLoading(true);
        //     fetch('/api/GetCustomRules')
        //         .then(response => response.json())
        //         .then(data => {
        //             setRules(data);
        //             setUpdatedRules(data);
        //             setIsLoading(false);
        //         })
        //         .catch(error => {
        //             setIsLoading(false);
        //             console.error(error);});
        // };

        //fetchData();

    }, [execute, rules]);

    const handleRuleChange = (index, value) => {
        const newRules = [...updatedRules];
        newRules[index] = value;
        setUpdatedRules(newRules);
    };

    const handleSubmit = () => {
        sethasSubmit(false)
        const updatedRulesJson = JSON.stringify(updatedRules);
        console.log('Submitting rules' + updatedRulesJson);
        fetch('/api/UpdateCustomRules', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: updatedRulesJson
        })
            .then(data => {
                console.log('Rules updated successfully');
                setRules(updatedRules);
                setSubmitMessage('Rules updated successfully!');
                setIsSubmitSuccessful(true);
                sethasSubmit(true)
            })
            .catch(error => {
                console.error(error);
                setSubmitMessage('Failed to update rules. Please try again.');
                setIsSubmitSuccessful(false);
                sethasSubmit(true)
            });
    };


    return (
        <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
        >
            <div className="container mt-3">
                {
                    isLoading && <div className="row">
                        <div className="col-12">
                            <h3>Loading...</h3>
                        </div>
                    </div>
                }

                {
                    !isLoading &&
                    <>
                        <div className="row">
                            <div className="col-12">
                                <h3>Custom filtering rules</h3>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-12 text-start">
                                <button className="btn btn-primary" onClick={() => setUpdatedRules(['', ...updatedRules])}>Add New Rule</button>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-12 text-start">
                                <ul className="list-group">
                                    {pageItems.map((rule, index) => {
                                        const actualIndex = (currentPage - 1) * ITEMS_PER_PAGE + index;
                                        return (
                                            <li key={actualIndex} className="list-group-item">
                                                <button className="btn btn-sm" onClick={() => {
                                                    const newUpdatedRules = [...updatedRules];
                                                    newUpdatedRules.splice(actualIndex, 1);
                                                    setUpdatedRules(newUpdatedRules);
                                                }}>Delete</button>
                                                <input
                                                    className='form-control'
                                                    type="text"
                                                    value={updatedRules[actualIndex]}
                                                    onChange={e => handleRuleChange(actualIndex, e.target.value)}
                                                />
                                            </li>
                                        );
                                    })}
                                </ul>
                                <div className='mt-2'>
                                    {(rules && rules.length > 0) && (
                                        <nav aria-label="Page navigation example">
                                            <ul className="pagination">
                                                {Array.from({ length: Math.ceil(rules.length / ITEMS_PER_PAGE) }, (_, i) => (
                                                    <li className="page-item">
                                                        <a class="page-link" onClick={(event) => {
                                                            event.preventDefault();
                                                            handlePageChange(i + 1);
                                                        }} key={i} href="/#">
                                                            {i + 1}
                                                        </a>
                                                    </li>
                                                ))}
                                            </ul>
                                        </nav>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="row mt-1">
                            <div className="col-12 text-start">
                                {(hasSubmit && submitMessage) && (
                                    <div className={`alert ${isSubmitSuccessful ? 'alert-success' : 'alert-danger'}`} role="alert">
                                        {submitMessage}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="row mt-1">
                            <div className="col-12 text-start">
                                <button className="btn btn-primary btn-sm mt-3" onClick={handleSubmit}>Submit</button>
                            </div>
                        </div>
                    </>
                }
            </div>
        </MsalAuthenticationTemplate>
    );
};

export default AdGuardRules;